
import vsButton from "vuesax";
import "vuesax/dist/vuesax.css";
import Vue from "vue";
Vue.use(vsButton);

export default {
  layout: "child",
  name: "IndexGroupId",
  components: {
    CommunityQuestionList: () =>
      import("@/components/CommunityQuestionList.vue"),
    ImageLock: () => import("@/components/ImageLock.vue"),
    navbar: () => import("@modoc-component/src.components.nav-bar/navbar.vue"),
    CommuntiyDropdownSetting: () =>
      import("~/components/molecules/CommunityDropdownSetting"),
  },
  async asyncData({ params, store }) {
    const data = {
      param_id: params.id,
      param_slug: "",
    };
    try {
      const dataFetch = await store.dispatch("public/getCommunityDetail", data);
      store.commit("community/SET_COMMUNITY_PROFILE", dataFetch);
      return {
        dataFetch,
      };
    } catch (error) {}
  },
  data: () => ({
    isBroadcastDialogShow: false,
    dataFetch: null,
    loading: {
      lists: false,
      detail: false,
      join: false,
      scroll: false,
    },
    buttonQr: false,
    isOwned: false,
    detail: {
      id: "",
      id_country: "",
      id_province: "",
      id_user: "",
      is_verified: "",
      community_name: "",
      description: "",
      slug: "",
      short_description: "",
      is_public: null,
      purpose_type: "",
      img_logo: null,
      img_background: null,
      updated_by: null,
      is_recommended_picked: "",
      city_name: "",
      total_member: null,
      member_data: {},
    },
    showAccordion: false,
    params_id: "",
    params_slug: "",
    topQuestionLists: [],
    active_report: false,
    report_item: null,
    report_id: null,
    report_others_answer: "",
    report_questions: [
      {
        text: "Gambar",
        id: 1,
      },
      {
        text: "Spam",
        id: 2,
      },
      {
        text: "Offensive",
        id: 3,
      },
      {
        text: "Abuse",
        id: 4,
      },
      {
        text: "SARA",
        id: 5,
      },
    ],
    activeTab: "discussion",
    discussion: {
      lists: [],
      offset: 1,
      limit: 5,
      total: 0,
    },
    media: {
      lists: [],
      offset: 1,
      limit: 5,
      total: 0,
    },
    own: {
      lists: [],
    },
    pinnded: [],
    rawLists: [],
    allData: [],
    filterPin: [],
    pageMember: 1,
    iconsSosmed: [
      "bxl-instagram",
      "bxl-twitter",
      "bxl-youtube",
      "bxl-facebook",
      "bx-calendar-event",
      "bxl-whatsapp",
      "bx-envelope",
      "bx-map",
      "bx-cart",
      "bx-link-alt",
    ],
    ads: {
      ads_1: {
        path: "/22728598014/Community_Above_Join",
        size: [320, 50],
        dom_id: "div-gpt-ad-1667189563496-0",
      },
    },
  }),
  watch: {
    activeTab() {
      this.pageMember = 1;
      this.fetchLists(this.activeTab);
    },
    "$store.state.auth.user.myProfile.id"() {
      this.setOwnedCommunity();
    },
  },
  mounted() {
    this.checkParams();
    this.fetchLists("discussion");
    this.scrollInfinite();
  },
  destroyed() {
    clearInterval(this.scrollInfinite);
  },
  beforeDestroy() {
    if (window.googletag) {
      window.googletag.destroySlots();
    }
  },
  methods: {
    scrollInfinite() {
      const tabs = this.activeTab;
      this.loading.scroll = true;
      setInterval(() => {
        if (this.pinnded.length + this[tabs].lists.length >= this[tabs].total) {
          clearInterval(this.scrollInfinite);
        } else {
          this.loadMore(tabs);
        }
        this.loading.scroll = false;
      }, 3000);
    },
    async copySign(link) {
      try {
        await this.$copyText(link);
        this.$notify.success({
          message: "Berhasil di copy",
          offset: 100,
          duration: 3500,
        });
      } catch ($e) {
        this.$notify.error({
          title: "Gagal",
          offset: 100,
          message: "Oops, terjadi kesalahan",
        });
      }
    },
    async submitJoinCommunity() {
      this.loading.join = true;
      const formData = new FormData();
      formData.append("id_community", this.detail.id);
      await this.$store
        .dispatch("community/postSubmitJoinCommunity", formData)
        .then((res) => {
          this.fetchCommunityDetail();
          this.loading.join = false;
        })
        .catch((err) => {
          this.loading.join = false;
          throw new Error(err);
        });
    },
    async submitLeaveCommunity() {
      this.loading.join = true;

      const formData = new FormData();
      // formData.append('id_community', this.detail.id)
      formData.append("status", "deleted");

      const payload = {
        id: this.detail.id_member,
        formData,
      };
      await this.$store
        .dispatch("community/postSubmitLeaveCommunity", payload)
        .then((res) => {
          this.fetchCommunityDetail();
          this.loading.join = false;
        })
        .catch((err) => {
          this.loading.join = false;
          throw new Error(err);
        });
    },
    checkParams() {
      if (this.$route.params) {
        this.params_slug = this.$route.query.slug;
        this.params_id = this.$route.params.id;
        this.fetchCommunityDetail();
      }
    },
    loadMore(val) {
      this[val].offset = this[val].offset + 1;
      this.fetchLists(val, true);
    },
    handleMemberDropdown(val, id) {
      switch (val) {
        case "set_member":
          return this.handleSetMember("position", "member", id);
        case "promote_admin":
          return this.handleSetMember("position", "admin", id);
        case "block":
          return this.handleSetMember("status", "blocked", id);
        case "remove":
          return this.handleSetMember("status", "deleted", id);
        case "active":
          return this.handleSetMember("status", "active", id);
        case "rejected":
          return this.handleSetMember("status", "rejected", id);
      }
    },
    handleShare(val, item) {
      if (!window) {
        return;
      }
      const base = window.location.host;
      const dateNow = new Date().getTime();
      const username = this.$store.state.auth.user.myProfile.slug
        ? this.$store.state.auth.user.myProfile.slug
        : "_" + Math.floor(Math.random() * 10000 + 1);
      const url = `https://${base}/mb/community/${item.id}?slug=${username}&_utm=${dateNow}`;
      switch (val) {
        case "fb":
          return window.open(
            "http://www.facebook.com/sharer/sharer.php?u=" +
              url +
              "&_ch=facebook",
            "_blank"
          );
        case "wa":
          return window.open(
            "https://api.whatsapp.com/send?text=" + url + "&_ch=whatsapp",
            "_blank"
          );
        case "tw":
          return window.open(
            "https://twitter.com/share?url=" + url + "&_ch=twitter",
            "_blank"
          );
        case "in":
          return window.open(
            "http://www.linkedin.com/shareArticle?mini=true&url=" +
              url +
              "&_ch=linkedin",
            "_blank"
          );
      }
    },
    async handleSetMember(key, val, id) {
      const params = `?params=${this.page}`;
      const formData = new FormData();
      formData.append(key, val);

      const payload = {
        id,
        formData,
        params,
      };
      await this.$store
        .dispatch("community/setCommunityMember", payload)
        .then(() => {
          this.$message({
            message: "Berhasil",
            type: "success",
          });
          this.refetch();
        })
        .catch(() => {
          this.$message({
            message: "Gagal menambahkan jawaban",
            type: "error",
          });
        });
    },
    handleDropdown(val, item) {
      switch (val) {
        case "edit":
          this.editQuestion(item);
          break;
        case "delete":
          this.confirmDelete(item);
          break;
        case "report":
          this.reportQuestion(item);
          break;
        case "leave":
          this.submitLeaveCommunity();
          break;
      }
    },
    async fetchLists(val, add = false) {
      this.loading.lists = true;
      if (add === false) {
        this[val].offset = 1;
        this[val].lists = [];
        this.rawLists = [];
        this.pinnded = [];
        this.filterPin = [];
      }
      if (val === "own") {
        const params = {
          id: this.params_id,
          page: this.pageMember,
        };
        await this.$store
          .dispatch("public/getMemberCommunity", params)
          .then((res) => {
            if (add) {
              this.own.lists.push(...res);
            } else {
              this.own.lists = res;
            }
            this.loading.lists = false;
          })
          .catch((err) => {
            this.loading.lists = false;
            throw new Error(err);
          });
      } else if (val === "media") {
        await this.$store
          .dispatch("public/getListMediaCommunity", this.params_id)
          .then((res) => {
            if (res.body.length !== 0) {
              this[val].lists.push(...res.body);
            }
            this.loading.scroll = false;
            this.loading.lists = false;
            this[val].total = res.total_records;
          })
          .catch((err) => {
            this.loading.lists = false;
            throw new Error(err);
          });
      } else {
        const params = `${this.params_id}/questions?limit=${this[val].limit}&p=${this[val].offset}`;
        await this.$store
          .dispatch("public/getListDiscussionCommunity", params)
          .then((res) => {
            if (res.body.length !== 0) {
              res.body.map((obj) => {
                if (obj.is_pinned === 1) {
                  this.filterPin.push(obj);
                  this.pinnded = [...new Set(this.filterPin)];
                } else {
                  this[val].lists.push(obj);
                }
              });
            }
            this.loading.scroll = false;
            this.loading.lists = false;
            this[val].total = res.total_records;
          })
          .catch((err) => {
            this.loading.lists = false;
            throw new Error(err);
          });
      }
    },
    checkPinned(val) {
      if (this.pinnded.length < 2) {
        this[val].lists = this.allData;
      } else {
        this[val].lists = this.rawLists;
      }
    },
    reportQuestion(val) {
      this.report_id = val.id;
      this.active_report = true;
    },
    editQuestion(val) {
      this.$router.push(`/add?id=${this.params_id}`);
    },
    confirmDelete() {
      this.$confirm("Apa kamu yakin mau menghapus Community ini?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      }).then(() => {
        this.deleteQuestion();
      });
    },
    async deleteQuestion() {
      await this.$store
        .dispatch("community/deleteCommunity", this.params_id)
        .then(() => {
          this.$message({
            message: "Berhasil delete community",
            type: "success",
          });
          this.$router.push("/community");
        })
        .catch(() => {
          this.$message({
            message: "Gagal delete community",
            type: "error",
          });
        });
    },
    getUrl(id) {
      return window.open(
        process.env.BASE_API + "view/communities/linkplus/" + id,
        "_blank"
      );
    },
    async fetchLinkPlusCommunity(item) {
      if (item.type === "link") {
        this.getUrl(item.id);
      } else {
        await this.$store
          .dispatch("public/getLinkPlusCommunity", item.id)
          .then((res) => {
            if (item.type === res.type) {
              this.copySign(res.link);
            }
          })
          .catch((err) => {
            throw new Error(err);
          });
      }
    },
    async fetchCommunityDetail() {
      this.loading.detail = true;
      const data = {
        param_id: this.params_id,
        param_slug: this.params_slug,
      };
      await this.$store
        .dispatch("public/getCommunityDetail", data)
        .then((res) => {
          this.detail = res || null;
          this.$store.commit("auth/SET_MEMBER", res.member_data);
          this.loading.detail = false;
          if (
            this.$route.query &&
            this.$route.query.autojoin &&
            !this.detail.is_member
          ) {
            this.submitJoinCommunity();
          }
          if (process.client) {
            setTimeout(() => {
              this.$defineSlots(this.ads.ads_1);
            }, 1000);
          }
        })
        .catch((err) => {
          this.loading.detail = false;
          throw new Error(err);
        });
    },
    async deleteCommunity() {
      const data = {
        param_id: this.params_id,
        param_slug: "",
      };
      await this.$store
        .dispatch("public/getCommunityDetail", data)
        .then((res) => {})
        .catch((err) => {
          throw new Error(err);
        });
    },
    setOwnedCommunity() {
      this.isOwned =
        Number(this.$store.state.auth.user.myProfile.id) ===
        Number(this.detail.id_user);
    },
    refetch() {
      this.fetchLists("own");
      this.fetchLists("discussion");
      this.fetchLists("media");
    },
    moreMember(val) {
      this.pageMember = val;
      this.fetchLists("own", true);
    },
  },
  head() {
    return {
      title:
        this.dataFetch && this.dataFetch.community_name
          ? `${this.dataFetch.community_name} Community` + " | Modoc"
          : "Modoc",
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content:
            this.dataFetch && this.dataFetch.community_name
              ? "Community" + " | Modoc"
              : "Modoc",
        },
        { hid: "og:type", property: "og:type", content: "article" },
        { hid: "og:site_name", property: "og:site_name", content: "Modoc" },
        {
          hid: "description",
          name: "description",
          content:
            this.dataFetch && this.dataFetch.description
              ? this.dataFetch.description
              : "",
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            this.dataFetch && this.dataFetch.description
              ? this.dataFetch.description
              : "",
        },
        {
          hid: "og:url",
          name: "og:url",
          content: process.env.BASE_URL + this.$route.path.substring(1),
        },
        {
          property: "og:image",
          content:
            this.dataFetch && this.dataFetch.img_background
              ? this.dataFetch.img_background
              : "https://static.modocapis.com/kotak/images/default-question.jpg",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      script: [
        {
          async: true,
          src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
        },
      ],
    };
  },
};
